<template>
  <div class="container h-100 pb-3">
    <div class="row">
      <div class="col">
        <h1><u>Adventskalender</u></h1>

        <img src="../assets/Einsendungen2022.png" class="img-fluid rounded " alt="...">

        <br><br>

        <p>Liebe Timolianer*innen,<br><br>
          wieder ist ein Jahr vergangen. Ein Jahr voller Ereignisse und Veränderungen. Nun ist es wieder an der Zeit sich auf kommende Events zu freuen; der Adventskalender steht vor der Tür! Und wie immer wollen wir diese Zeit mit euch auch auf Timolia feiern!<br><br>

          Und ein Teil von einem der bedeutensden Events auf Timolia zu werden ist gar nicht so schwer: <b>Wir wollen eure Jump and Runs für den Timolia Adventskalender 2023!</b><br><br>

          Traditionell feiern wir auf Timolia Weihnachten mit einem Adventskalender, der aus 24 einzigartigen Jump'n'Runs besteht. In den letzten Jahren haben sich tausende User von den Maps begeistern lassen. Sei auch du Teil dieses großen Events!<br><br>

          Wenn du Lust hast, deine Map im Adventskalender 2023 zu sehen, dann ist das hier JETZT deine Chance! Wir freuen uns über jede Einsendung und jedes Jump'n'Run! Frohes Schaffen wünschen wir dir! :)<br><br>

          Auch in diesem Jahr stehen dir zwei Möglichkeiten zum Einsenden von Maps zur Verfügung:
        </p>

        <div class="accordion mb-4" id="accordionTimolia">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#buildOnTimolia" aria-expanded="true" aria-controls="flush-collapseOne">
                1) Eine Map auf Timolia bauen und einsenden
              </button>
            </h2>
            <div id="buildOnTimolia" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionTimolia">
              <div class="accordion-body">
                <p>
                  Ab sofort besteht zusätzlich zum Veröffentlichen einer Map in JumpWorld die Möglichkeit, dein JnR für den Adventskalender einzusenden!<br><br>

                  Achtung! Jump and Runs, die eingesendet werden, werden vorerst nicht auf JumpWorld veröffentlicht. Erst <b>nach</b> Abschluss des Adventskalenders werden eure Maps auf JumpWorld normal spielbar - egal, ob sie es in den Adventskalender geschafft haben oder nicht. <b>Tagessieger</b> können diese Maps dann aber <b>nicht</b> mehr werden!<br><br>

                  Bitte beachte folgende Hinweise, um deinem Jump and 'Run die Möglichkeit zu wahren, als Türchen im Adventskalender zu erscheinen:<br><br>
                </p>

                <h5>Was darf ich, was soll ich und was muss ich tun?</h5>
                <ul>
                  <li>Der durchschnittliche Spieler sollte <b>nicht mehr als 15 Minuten</b> benötigen, um das Jump and Run zu absolvieren.</li>
                  <li>Gesucht sind <b>Speedrun-geeignete Maps</b>. Langwierige Ideen wie Quiz, Dropper, Escape the Room und Geschichten, die nur durch Schilderwände Sinn ergeben, sind für den Adventskalender ungeeignet</li>
                  <li>Genauso unpassend sind gemeine Sprünge. Zu lange an einem Abschnitt zu sitzen, macht niemandem Spaß – bau einzelne Sprünge <b>nicht zu schwer!</b></li>
                  <li>Da der Adventskalender mit der Version <b>1.20+</b> bespielbar ist, kann es bei einigen Blöcken zu Problemen kommen. Pass also mit Glasscheiben, Eisengittern und allen weiteren Blöcken, die ihr Verhalten in den höheren Versionen verändert haben, auf! Es werden im Kalender die 1.20 Versionen der Blöcke verwendet werden!</li>
                  <li><b>Kristalle</b> dürfen auf den Maps <b>nicht</b> platziert werden.</li>
                  <li>Lass deiner <b>Phantasie und Kreativität</b> freien Lauf! Das Abbauen und Zusammenfassungen bereits vorhandener Maps sind langweilig! Das <b>Thema</b> muss <b>nicht weihnachtlich</b> oder winterlich sein! Gute Ideen sind gefragt!</li>
                  <li>Nenne gerne alle beteiligten Erbauer beim Namen, doch Werbung ist hier <b>nicht</b> erlaubt. Es gelten die allgemeinen Timolianischen Regeln!</li>
                  <li>Um dir das Bauen zu erleichtern, kannst du wie gewohnt auf die <b>JumpWorld Mechaniken und Funktionen</b> zurückgreifen. Pros und höher auch auf <b>Köpfe</b> und <b>Armorstands</b>. Auch <b>WorldEdit</b> kann in diesem Jahr <b>erstmals genutzt werden</b>!</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#buildExternal" aria-expanded="false" aria-controls="flush-collapseTwo">
                2) Eine Map extern bauen und anschließend für den Adventskalender einreichen
              </button>
            </h2>
            <div id="buildExternal" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionTimolia">
              <div class="accordion-body">
                <p>
<!--                TODO: Channel -->
                  Wenn du die 1.20 Blöcke und Mechaniken voll ausnutzen möchtest oder einen privaten Bauserver mit besonderen Plugins wie VoxelSniper besitzt, kannst du dein Jump and Run auch dort bauen und anschließend auf unserem Discord in einem Support-Ticket einreichen.<br><br>
                </p>

                <h5>Das Wichtigste im Überblick</h5>

                <p>
                Bitte stelle zum Einsenden deiner Map in einer Nachricht deine Map vor und sende vor allem <b>aussagekräftige Screenshots</b> mit!<br>
                  Ein <b>Downloadlink</b> der Map sollte vorerst <b>nicht enthalten sein</b> - dieser wird dann bei Interesse von uns per Direktnachricht im Discord erfragt.<br>
                  Nenne in deinem Beitrag <b>alle beteiligten Erbauer</b> und stelle sicher, dass sie alle mit der Nutzung der Map als Adventskalendermap auf Timolia einverstanden sind!<br>
                  Maps, die <b>schon einmal an anderer Stelle verwendet/zum Download bereitgestellt</b> wurden, dürfen <b>nicht mehr eingesendet</b> werden!<br>
                  Auch das Nutzen/<b>Einsenden fremder Maps</b> oder Bauwerke ist selbstverständlich <b>nicht erlaubt!</b><br><br>
                </p>

                <h5>Was darf ich, was soll ich und was muss ich tun?</h5>
                <ul>
                  <li>Der durchschnittliche Spieler sollte <b>nicht mehr als 15 Minuten</b> benötigen, um das Jump and Run zu absolvieren.</li>
                  <li>Gesucht sind <b>Speedrun-geeignete Maps</b>. Langwierige Ideen wie Quiz, Dropper, Escape the Room und Geschichten, die nur durch Schilderwände Sinn ergeben, sind für den Adventskalender ungeeignet</li>
                  <li>Genauso unpassend sind gemeine Sprünge. Zu lange an einem Abschnitt zu sitzen, macht niemandem Spaß – bau einzelne Sprünge <b>nicht zu schwer!</b></li>
                  <li>Da der Adventskalender mit der Version <b>1.20+</b> bespielbar ist, kann es bei einigen Blöcken zu Problemen kommen. Pass also mit Glasscheiben, Eisengittern und allen weiteren Blöcken, die ihr Verhalten in den höheren Versionen verändert haben, auf! Es werden im Kalender die 1.20 Versionen der Blöcke verwendet werden!</li>
                  <li><b>Kristalle</b> dürfen auf den Maps <b>nicht</b> platziert werden.</li>
                  <li>Lass deiner <b>Phantasie und Kreativität</b> freien Lauf! Das Abbauen und Zusammenfassungen bereits vorhandener Maps sind langweilig! Das <b>Thema</b> muss <b>nicht weihnachtlich</b> oder winterlich sein! Gute Ideen sind gefragt!</li>
                  <li>Nenne gerne alle beteiligten Erbauer beim Namen, doch Werbung ist hier <b>nicht</b> erlaubt. Es gelten die allgemeinen Timolianischen Regeln!</li>
                  <li>Um dir das Bauen zu erleichtern, kannst du wie gewohnt auf die <b>JumpWorld Mechaniken und Funktionen</b> zurückgreifen. Pros und höher auch auf <b>Köpfe</b> und <b>Armorstands</b>. Auch <b>WorldEdit</b> kann in diesem Jahr <b>erstmals genutzt werden</b>!</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <p>
          <i>Wir behalten uns vor, kleinere Änderungen wie optische Anpassungen, das Korrigieren von Baufehlern, das Abändern von Sprüngen und das Entfernen/Ändern von Schildern, Texten und dem Titel nach Abgabe an den Maps vorzunehmen!</i><br><br>

          <b>Neu dieses Jahr</b>: Sollte dein JnR angenommen werden, erhältst du ein einzigartiges Minipet in JumpWorld 1.20!<br><br>

          Wir wünschen viel Spaß und Erfolg beim Bauen deines Adventskalender-Türchens und freuen uns auf deine Einsendung!<br>
          Dein Timolia Team ♥
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Adventskalender",
  mounted() {
    if(window.location.hash) {
      location.href = window.location.hash;
    }
  }
}
</script>

<style scoped>
@media (prefers-color-scheme: dark)
.accordion-button:not(.collapsed) {
  color: #121212;
  background-color: orange;
  box-shadow: inset 0 -1px 0 rgb(250 250 250 / 13%);
}

.accordion-button:not(.collapsed) {
  color: #313131;
  background-color: orange;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}


.jumpRef {
  color: var(--bs-body-color);;
}

@media (prefers-color-scheme: dark) {
  .jumpRef {
    color: white;
  }
}

.jumpRef:hover {
  color: orange;
}

.custom-li::marker {
  content: attr(customContent) " ";
}

.tutorial-image {
  margin-bottom: .5rem;
}

.topic {
  margin-top: 2rem;
  text-decoration: underline;
  font-weight: bold;
}

.copy:hover {
  color: grey;
  cursor: pointer;
}

.marked-warning {
  color: orangered;
}

.marked-notice {
  color: yellow;
}

.marked {
  color: coral;
}

.player {
  color: #AAAAAA;
}

.pro {
  color: #FFAA00;
}

.mvp {
  color: #55FF55;
}

.expert {
  color: #55FFFF;
}

.admin {
  color: #0000AA;
}

.dev {
  color: #00AA00;
}

.mod {
  color: #AA0000;
}

.sup {
  color: #FFFF55;
}
</style>
